import styled from 'styled-components';
import BgImage from 'common/assets/image/syncStage/pattern-4@2x.png';
import BgFooterImage from 'common/assets/image/bg-footer.png';

const FooterWrapper = styled.footer`
  background-image: url(${BgImage});
  background-position: bottom left;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0;
  overflow: hidden;

  :before {
    content: '';
    background-image: url(${BgFooterImage});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .gap {
    gap: 30px;
  }

  .separator {
    border: none;
    background-color: #5d729e;
    height: 1px;
    margin: 40px 0 50px 0;
  }

  .label {
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
  }
  .link {
    text-decoration: none;
    color: #36dbfd;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
  }
`;

export default FooterWrapper;
