import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { Modal } from '@redq/reuse-modal';

import { DrawerProvider } from 'common/contexts/DrawerContext';
import { syncStageTheme } from 'common/theme/syncStage';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, ContentWrapper } from 'containers/SyncStage/syncStage.style';
import Navbar from 'containers/SyncStage/Navbar';
import Footer from 'containers/SyncStage/Footer';
import Seo from 'components/seo';
import { ModalProvider } from 'common/contexts/ModalContext';
import { handleNewsletter } from '../common/handlers';

function Layout({ children }) {
  return (
    <ThemeProvider theme={syncStageTheme}>
      <Fragment>
        <Seo title="SyncStage | Ultra Fast Audio Pipeline" />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <ModalProvider>
            <Sticky top={0} innerZ={100} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar handleNewsletter={handleNewsletter} />
              </DrawerProvider>
            </Sticky>
            {children}
            <Footer />
          </ModalProvider>
        </ContentWrapper>
        <Modal />
      </Fragment>
    </ThemeProvider>
  );
}
export default Layout;
