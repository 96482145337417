import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import Logo from 'common/components/UIElements/Logo';
import Box from 'common/components/Box';
import Container from 'common/components/UI/Container';
import { DrawerContext } from 'common/contexts/DrawerContext';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import LogoImage from 'common/assets/image/syncStage/logo.png';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavbarStyleWrapper, MobileMenuTrigger } from './navbar.style';

const NavbarX = ({ navbarStyle, logoStyle, button, row, menuWrapper, handleNewsletter, navLink }) => {
  const Data = useStaticQuery(graphql`
    query {
      syncStageJson {
        MENU_ITEMS {
          label
          path
          offset
          sub_menus {
            label
            path
          }
        }
      }
    }
  `);
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const renderMenu = (menu, index) => {
    if (menu.sub_menus && menu.sub_menus.length > 0) {
      return (
        <NavDropdown key={`menu-item-${index}`} title={`${menu.label}`} {...navLink}>
          {menu.sub_menus.map((sub_menu, index) => (
            <NavDropdown.Item href={`${sub_menu.path}`} key={`submmenu-item-${index}`}>
              {sub_menu.label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    } else {
      return (
        <Nav.Link href={`${menu.path}`} {...navLink} key={`menu-item-${index}`}>
          {`${menu.label}`}
        </Nav.Link>
      );
    }
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo href="/" logoSrc={LogoImage} title="SyncStage" logoStyle={logoStyle} className="main-logo" />
          <Box {...menuWrapper}>
            <NavbarStyleWrapper>
              <Navbar bg="light" expand="lg">
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav>
                    {Data.syncStageJson.MENU_ITEMS.map(renderMenu)}
                    <Nav.Link onClick={handleNewsletter}>Newsletter</Nav.Link>
                    <Nav.Link href={process.env.DOCUMENTATION_URL} target="_blank" rel="noreferrer">
                      Documentation
                    </Nav.Link>
                    <Nav.Link href={process.env.LOGIN_TO_CONSOLE_URL} target="_blank" rel="noreferrer">
                      Login
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </NavbarStyleWrapper>
            <a href={process.env.REQUEST_DEMO_URL} target="_blank" rel="noreferrer">
              <Button {...button} title="Sign Up" />
            </a>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<MobileMenuTrigger barColor="#36dbfd" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.syncStageJson.MENU_ITEMS}
                handleNewsletter={handleNewsletter}
                drawerClose={true}
                offset={-100}
              />
              <a href={process.env.REQUEST_DEMO_URL} className="navbar_drawer_button" target="_blank" rel="noreferrer">
                <Button {...button} title="Sign Up" />
              </a>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

NavbarX.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
  navLink: PropTypes.object,
};

NavbarX.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    background: `linear-gradient(90deg, rgb(127, 1, 160) 0%, rgb(1, 73, 182) 92.91%)`,
    minHeight: 'auto',
    height: '40px',
    style: {
      color: 'white',
      background: `linear-gradient(90deg, rgb(127, 1, 160) 0%, rgb(1, 73, 182) 92.91%)`,
    },
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
  navLink: {
    type: 'nav',
  },
};

export default NavbarX;
