import styled from 'styled-components';
import HamburgMenu from 'common/components/HamburgMenu';

export const NavbarStyleWrapper = styled.section`
  .navbar-nav {
    display: flex;
    padding: 20px;
  }
  .nav-link {
    color: white;
    padding: 0px 10px;
  }
  .nav-link:hover {
    color: rgb(54, 219, 253);
  }
  .dropdown-menu {
    display: none;
    border: 1px solid gray;
    background-color: rgba(50, 50, 50, 0.8);
    position: absolute;
    padding: 5px 10px 10px 10px;
    margin-top: 10px;
    border-radius: 3px;
  }
  .dropdown-menu.show {
    display: block;
    transition: all 0.15s ease-in-out 0s;
  }
  .dropdown-item {
    color: white;
    display: flex;
    padding-top: 8px;
  }
  .dropdown-item:hover {
    color: rgb(54, 219, 253);
  }

  .dropdown-toggle {
    position: relative;
  }

  .dropdown-toggle::after {
    display: block;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
  }
  @media (max-width: 990px) {
    .navbar-nav {
      display: none;
    }
  }
`;

export const MobileMenuTrigger = styled(HamburgMenu)`
  margin-left: 15px;
`;
