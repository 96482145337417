import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Box from 'common/components/Box';
import Logo from 'common/components/UIElements/Logo';
import Container from 'common/components/UI/Container';
import FooterWrapper from './footer.style';
import { ModalContext } from 'common/contexts/ModalContext';
import { openModal } from '@redq/reuse-modal';

import Image from 'common/components/Image';
import LogoImage from 'common/assets/image/footer-logo.png';
import LinkedinImage from 'common/assets/image/syncStage/linkedin-icon.svg';
import GithubImage from 'common/assets/image/syncStage/github-icon.svg';

const Footer = ({ logoStyle }) => {
  const { state } = useContext(ModalContext);
  useEffect(() => {
    if (state?.isOpen) {
      openModal({
        config: {
          className: 'video-modal',
          disableDragging: true,
          width: '100%',
          height: '100%',
        },
        //component: RequestDemo,
        componentProps: {},
        //closeComponent: CloseModalButton,
        closeOnClickOutside: true,
      });
    }
  }, [state]);

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" flexBox flexWrap="wrap" alignItems="center" justifyContent="space-between">
          <Box>
            <Logo href="https://opensesame.media" logoSrc={LogoImage} title="SyncStage" logoStyle={logoStyle} />
          </Box>
          <Box></Box>
          <Box>
            <a href="https://github.com/opensesamemedia" target="_blank" rel="noreferrer">
              <Image
                src={GithubImage}
                alt="SyncStage Github"
                style={{ color: 'white', display: 'inline', height: '32px', margin: '6px' }}
              ></Image>
            </a>
            <a href="https://www.linkedin.com/company/opensesamemedia" target="_blank" rel="noreferrer">
              <Image src={LinkedinImage} alt="SyncStage LinkedIn" style={{ display: 'inline' }}></Image>
            </a>
            {/* <a
              href="https://join.slack.com/t/opensesamemedia/shared_invite/zt-162251zdd-OXcue6sakNB9iDeVbHOE6g"
              target="_blank"
            >
              <Image src={SlackImage} alt="SyncStage Slack" style={{display: "inline"}}></Image>
            </a> */}
          </Box>
        </Box>
        <div className="separator" />
        <Box className="gap" flexBox flexWrap="wrap" justifyContent="space-between">
          <Box className="gap" flexBox>
            <Link to="/policy" className="link">
              PRIVACY POLICY
            </Link>
            <Link to="/terms" className="link">
              TERMS & CONDITIONS
            </Link>
          </Box>
          <span className="label">Copyright © 2024 Open Sesame Media, Inc.</span>
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Footer.defaultProps = {
  logoStyle: {
    mb: '15px',
  },
  textStyle: {
    color: '#fff',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '150%',
  },
};

export default Footer;
